/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useContext} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {
  MenuIcon,
  PresentationChartBarIcon,
  PrinterIcon,
  SupportIcon, TableIcon,
  XIcon,
} from '@heroicons/react/outline'
import {FormattedMessage} from "react-intl";
import {Context} from "../App";
import SetilioLogo from "../assets/SetilioLogo";


export default function Navbar(props: {embedded?: boolean}) {
  const context = useContext(Context);

  const solutions = [
    {
      name: 'present',
      href: '//present.setilio.com',
      icon: PresentationChartBarIcon,
    },
    {
      name: 'scan',
      href: '//scan.setilio.com',
      icon: PrinterIcon,
    },
    {
      name: 'planning',
      href: '//planning.setilio.com',
      icon: TableIcon,
    },
    {
      name: 'support',
      href: '//support.setilio.com',
      icon: SupportIcon
    },
  ]

  return (
    <Popover className={`${(props.embedded ?? false) ? "absolute" : "relative"} z-[100] w-full subpixel-antialiased`}>
      <div className="flex justify-between items-center px-6 py-6 sm:px-8 md:justify-start md:space-x-10">
        <div>
          <a href="/" className="flex">
            <span className="sr-only">setilio scan</span>
            <SetilioLogo
              className="h-5 w-auto sm:h-6 fill-current fill-blue-midnight"
            />
          </a>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button
            className="backdrop-brightness-95 rounded-md p-2 inline-flex items-center justify-center text-blue-mid hover:text-stone-dark focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-regular">
            <span className="sr-only"><FormattedMessage id={"nav.openMenu"}/></span>
            <MenuIcon className="h-6 w-6" aria-hidden="true"/>
          </Popover.Button>
        </div>
        <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
          <nav className="flex space-x-10 text-sm">
            <a href="mailto:present@setilio.com" className="font-medium text-stone-dark hover:text-blue-midnight">
              present
            </a>
            <a href="//scan.setilio.com" className="font-medium text-stone-dark hover:text-blue-midnight">
              scan
            </a>
            <a href="mailto:planning@setilio.com" className="font-medium text-stone-dark hover:text-blue-midnight">
              planning
            </a>
            <a href="//support.setilio.com" className="font-medium text-stone-dark hover:text-blue-midnight">
              support
            </a>
          </nav>
          <div className="flex items-center md:ml-12">
            <div className={"flex flex-row gap-x-4"}>
              <button
                className="text-sm font-medium text-stone-dark hover:text-blue-midnight"
                onClick={() => context.selectLanguage("de")}
              >
                DE
              </button>
              <button
                className="text-sm font-medium text-stone-dark hover:text-blue-midnight"
                onClick={() => context.selectLanguage("en")}
              >
                EN
              </button>
            </div>

            <a
              href="mailto:info@setilio.com"
              className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-regular hover:bg-green-dark"
            >
              <FormattedMessage id={"nav.contact"}/>
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus
                       className="absolute z-[10] top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div
            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-stone-verylight">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <SetilioLogo
                    className="h-7 w-auto text-blue-midnight"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-stone-regular hover:text-stone-dark hover:bg-stone-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-regular">
                    <span className="sr-only"><FormattedMessage id={"nav.closeMenu"}/></span>
                    <XIcon className="h-6 w-6" aria-hidden="true"/>
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-6">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-lg hover:bg-stone-light"
                    >
                      <div
                        className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-green-regular text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true"/>
                      </div>
                      <div className="ml-4 text-base font-medium text-blue-midnight">{item.name}</div>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="px-5 pb-5 pt-4">
              <div className="mb-4 text-center text-sm text-stone-dark flex flex-row gap-x-3 justify-center">
                <button
                  className="text-sm font-medium text-stone-dark hover:text-blue-midnight px-3 py-1"
                  onClick={() => context.selectLanguage("de")}
                >
                  DE
                </button>
                <button
                  className="text-sm font-medium text-stone-dark hover:text-blue-midnight px-3 py-1"
                  onClick={() => context.selectLanguage("en")}
                >
                  EN
                </button>
              </div>
              <a
                href="https://sso.itmc.tu-dortmund.de/openam/UI/Login"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-regular hover:bg-green-dark"
              >
                <FormattedMessage id={"nav.contact"}/>
              </a>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
