import React from 'react';

const SetilioLogo = (props: { className: string, logoColor?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={props.className} width="1742.734" height="528"
         viewBox="0 0 1742.734 528">
      <defs>
        <clipPath id="a">
          <rect width="443.338" height="528" fill="none"/>
        </clipPath>
      </defs>
      <g transform="translate(-315 -261)">
        <g transform="translate(563.034 556.399)" fill={props.logoColor ?? ""}>
          <g transform="translate(-248.034 -295.4)" clip-path="url(#a)">
            <path
              d="M328.5,144.545a15.44,15.44,0,0,0-24-3.363L4.864,429.045c-11.619,11.16-.459,30.422,14.982,25.989l399-115.726a15.44,15.44,0,0,0,9.02-22.473Z"
              transform="translate(0.028 72.385)"/>
            <path
              d="M419.053.625,19.9,116.2a15.44,15.44,0,0,0-9.173,22.626l99.522,172.29a15.44,15.44,0,0,0,24,3.363L433.882,26.614C445.5,15.454,434.341-3.808,418.9.625"
              transform="translate(4.564 -0.014)"/>
          </g>
        </g>
        <path
          d="M113.337,7.056c59.094,0,102.753-25.578,102.753-74.97,0-57.771-46.746-67.914-86.436-74.529-28.665-5.292-54.243-7.5-54.243-23.373,0-14.112,13.671-20.727,31.311-20.727,19.845,0,33.516,6.174,36.162,26.46h66.15c-3.528-44.541-37.926-72.324-101.871-72.324-53.361,0-97.461,24.7-97.461,72.324,0,52.92,41.9,63.5,81.144,70.119,29.988,5.292,57.33,7.5,57.33,27.783,0,14.553-13.671,22.491-35.28,22.491-23.814,0-38.808-11.025-41.454-33.516H3.528C5.733-23.373,47.187,7.056,113.337,7.056Zm238.581-.441c28.665,0,51.6-7.5,70.56-20.727,19.845-13.671,33.075-33.075,37.926-53.361H390.285c-6.174,14.112-18.522,22.491-37.485,22.491-29.547,0-46.3-18.963-50.715-49.392H464.373c.441-45.864-12.789-85.113-39.69-109.809-19.4-17.64-44.982-28.224-77.175-28.224-68.8,0-115.983,51.6-115.983,119.07C231.525-44.982,277.389,6.615,351.918,6.615ZM302.526-137.151c4.851-26.9,18.963-44.1,46.3-44.1,23.373,0,40.131,17.2,42.777,44.1ZM573.741,2.646c19.4,0,33.075-1.764,39.249-3.528V-53.361c-2.646,0-9.7.441-15.876.441-15.435,0-25.137-4.41-25.137-22.05V-180.81H612.99v-44.982H571.977v-71.442H501.858v71.442H471.87v44.982h29.988V-59.535C501.858-11.025,531.846,2.646,573.741,2.646ZM639.009,0h71.883V-225.792H639.009Zm0-257.1h71.883v-58.212H639.009ZM745.731,0H818.5V-315.315H745.731Zm107.6,0h71.883V-225.792H853.335Zm0-257.1h71.883v-58.212H853.335Zm216.531,213c-31.311,0-47.628-27.342-47.628-68.355s16.317-68.8,47.628-68.8,48.069,27.783,48.069,68.8S1101.177-44.1,1069.866-44.1Zm.441,51.156c72.765,0,120.393-51.6,120.393-119.511s-47.628-119.511-120.393-119.511c-72.324,0-120.834,51.6-120.834,119.511S997.983,7.056,1070.307,7.056Z"
          transform="translate(867.034 660)" fill="#11142b"/>
      </g>
    </svg>
  );
};

export default SetilioLogo;
