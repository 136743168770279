import React from 'react';
import {FormattedMessage} from "react-intl";

const Hero = () => {
  return (
    <>
      {/* Hero */}
      <div className="bg-hero min-h-screen">
        <div className={"flex flex-col min-h-screen px-8"}>
          <div className={"h-24"}/>
          <div className={"flex flex-col flex-1 justify-center items-center pb-20"}>
            <div className={"flex flex-col"}>
              <h1
                className="text-4xl tracking-tight font-extrabold text-blue-midnight sm:text-6xl lg:max-w-2xl">
                <FormattedMessage id={"hero.title"}/>
              </h1>
              <p
                className={"sm:text-lg lg:text-2xl max-w-5xl text-blue-mid leading-5 mt-5"}>
                <FormattedMessage id={"hero.desc"}/>
              </p>
            </div>
          </div>
          <div className={"h-24"}/>
        </div>
        {/*<div*/}
        {/*  className="mx-auto max-w-md px-4 sm:max-w-5xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:items-center">*/}
        {/*  <div className="lg:pt-20 xl:pt-24 pb-10 flex flex-col justify-center text-center">*/}
        {/*    /!*<InfoPretitle/>*!/*/}
        {/*    <h1*/}
        {/*      className="mt-4 text-4xl max-w-xl lg:max-w-3xl mx-auto tracking-tight font-extrabold text-blue-midnight sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl lg:px-20">*/}
        {/*      <FormattedMessage id={"hero.title"}/>*/}
        {/*    </h1>*/}
        {/*    <p*/}
        {/*      className={"text-base px-4 lg:text-lg mt-3 sm:mt-5 max-w-lg lg:max-w-lg mx-auto text-blue-mid leading-6"}>*/}
        {/*      <FormattedMessage id={"hero.desc"}/>*/}
        {/*    </p>*/}
        {/*  </div>*/}


        {/*</div>*/}
      </div>
    </>
  );
};

export default Hero;
