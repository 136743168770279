import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import {IntlProvider} from 'react-intl';
import German from './lang/de.json';
import English from './lang/en.json';

export const Context = React.createContext<{ locale: string, selectLanguage: (lang: string) => void }>({
  locale: "en",
  selectLanguage: (lang: string) => {
    console.log("App has not overwritten selectLanguage yet, but user requested " + lang)
  }
});

function App() {
  const [locale, setLocale] = useState<string>(navigator.language);
  const [messages, setMessages] = useState({});

  function selectLanguage(lang: string, autoSet: boolean = false) {
    if (lang === "de") {
      setMessages(German);
      setLocale("de");
      document.querySelectorAll("html")[0].setAttribute("lang", "de");
    } else {
      setMessages(English);
      setLocale("en");
      document.querySelectorAll("html")[0].setAttribute("lang", "en");
    }

    if (!autoSet) localStorage.setItem("preferred-lang", lang);
  }

  useEffect(() => {
    const preferredLang = localStorage.getItem("preferred-lang");
    selectLanguage(preferredLang ?? navigator.language, true);
  }, [])

  return (
    <Context.Provider value={{locale, selectLanguage}}>
      <IntlProvider defaultLocale="en" locale={locale} messages={messages}>
        <div className={"bg-gray-50 antialiased"}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </BrowserRouter>
        </div>
      </IntlProvider>
    </Context.Provider>
  );
}

export default App;
